export enum MovementManagerInsertModeEnum {

  Stock = 5,
  // NewItem = 10, // TODO: Da capire come muoverci... non conosciamo ancora la discriminante per la visualizzazione di questa modalità di inserimento
  FreeInput = 15,
  Offer = 20

}

interface InsertModeDetails {
  description: string;
  icon: string;
}

export const insertModeDetailsMap: Record<MovementManagerInsertModeEnum, InsertModeDetails> = {
  [MovementManagerInsertModeEnum.Stock]: {
    description: "insertMode.stock",
    icon: 'warehouse'
  },
  // [MovementManagerInsertModeEnum.NewItem]: {
  //   description: "insertMode.new-item",
  //   icon: 'add'
  // },
  [MovementManagerInsertModeEnum.FreeInput]: {
    description: "insertMode.free-input",
    icon: 'edit_square'
  },
  [MovementManagerInsertModeEnum.Offer]: {
    description: "insertMode.offer",
    icon: 'edit_square'
  }
};
