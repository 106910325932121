import { Component, Input, OnInit } from '@angular/core';
import { CtModelConfiguration, CtModelType } from '@ctsolution/ct-framework';
import { SchedulerRouteData } from '../../../_core/route-data/schedule.route-data';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';
import { SchedulerListConfiguration } from './scheduler-list.configuration';
import { SchedulerService } from '../scheduler.service';
import { Router } from '@angular/router';
import { CTMGeneralService } from '../../../_core/lib/general.service';

@Component({
  selector: 'app-scheduler-list',
  templateUrl: './scheduler-list.component.html',
  styleUrls: ['./scheduler-list.component.scss']
})
export class SchedulerListComponent implements OnInit {

  configuration : CtModelConfiguration<any> | null = null;
  @Input() schedulerListConfiguration : SchedulerListConfiguration | null = null;

  constructor(private schedulerService: SchedulerService,private router : Router,private generalService : CTMGeneralService) { }

  ngOnInit() {
    this.setup()
  }


  setup(){

    {

      const configuration =CtModelConfiguration.create()
      .setRouteData(SchedulerRouteData().setModelType(CtModelType.DATATABLE))
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setDisableAutoQueryFilter(true);


      if(this.schedulerListConfiguration?.Filter)
        configuration
          .filterValues
          .addConstantFilter(
            this.schedulerListConfiguration?.Filter
          )

      configuration
        .setOnCreate(() => this.onCreate())
        .setOnEdit((Event : any) => this.onEdit(Event))

      this.configuration = configuration;

      }

  }

  async onCreate(){

    if(this.schedulerListConfiguration?.Action)
     await this.schedulerListConfiguration.Action(null)

    if(this.schedulerListConfiguration?.param)
    this.schedulerService.navigateToPath(this.schedulerListConfiguration?.param)


  }

  onEdit(Event : any){

    this.router.navigate(['scheduler', 'calendar'], {
      queryParams: { IdEvent: Event.Oid }
    });


  }
}
